import ReactGA from 'react-ga';

import { Configuration } from 'types/Configuration';
import BlackbirdUserDto from 'types/Users/User';

export function initializeGoogleAnalytics(
    { googleAnalyticsKey }: Configuration,
    { userId, title, department }: BlackbirdUserDto
) {
    const _defaultValue = 'Unknown';

    const TRACKING_ID = googleAnalyticsKey;
    ReactGA.initialize(TRACKING_ID, { debug: false }); //set debug = false to see the GA events in console.

    ReactGA.set({
        user_id: userId,
        dimension1: title || _defaultValue,
        dimension2: department || _defaultValue,
    });
}

export function sendEvent(args: ReactGA.EventArgs) {
    ReactGA.event(args);
}
