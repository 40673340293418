import { z } from 'zod';

import { DateRangeInputSchema } from 'types/searchSchemas/DateRangeInputSchema';
import { ExtendedCspSubPropertyInputSchema } from 'types/searchSchemas/ExtendedCspSubPropertyInputSchema';
import { LeaseTypeEnum } from 'types/searchSchemas/SearchFieldSchemas';
import { NumericRangeInputSchema } from '../searchSchemas/NumericRangeInputSchema';

export const CompanySearchParametersInputSchema = z.object({
    values: z.array(
        z.object({
            companyName: z.array(z.string()).optional(),
            industryCluster: z.string().optional(),
        })
    ),
});

export type CompanySearchParametersInput = z.infer<typeof CompanySearchParametersInputSchema>;

export const CspLeaseInputSchema = z.object({
    executionDate: DateRangeInputSchema.optional(),
    expirationDate: DateRangeInputSchema.optional(),
    totalArea: NumericRangeInputSchema.optional(),
    firstYearRentAmount: NumericRangeInputSchema.optional(),
    leaseType: z.array(LeaseTypeEnum).optional(),
    isConfidential: z.boolean().default(false),
    companies: CompanySearchParametersInputSchema.optional(),
    properties: ExtendedCspSubPropertyInputSchema.optional(),
});

const CspLeaseInputSchemaWithOptionalIsConfidential = CspLeaseInputSchema.extend({
    isConfidential: CspLeaseInputSchema.shape.isConfidential.optional(),
});

export type CspLeaseInput = z.infer<typeof CspLeaseInputSchemaWithOptionalIsConfidential>;
