import { z } from 'zod';

const CspMarketHierarchyInputSchema = z.object({
    marketLevel1: z.string(),
    marketLevel2: z.string().optional(),
    marketLevel3: z.string().optional(),
    subMarketCluster: z.string().optional(),
});

export const CspMarketsHierarchyInputSchema = z.object({
    values: z.array(CspMarketHierarchyInputSchema),
});

export type ListOfMarketHierarchyParameterInput = z.infer<typeof CspMarketsHierarchyInputSchema>;
