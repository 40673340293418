import { initialize, LDClient } from 'launchdarkly-js-client-sdk';

import config from 'utils/config';

let client: LDClient | undefined;
let resolvePromise: (value: LDClient) => void;
let clientPromise = new Promise<LDClient>((resolve) => {
    resolvePromise = resolve;
});

export function _reset(): void {
    client = undefined;
    clientPromise = new Promise<LDClient>((resolve) => {
        resolvePromise = resolve;
    });
}

export function _setClient(newClient: LDClient): void {
    // If there was already a client defined, redefine the clientPromise.
    // This is so that any new subscribers will retrieve the new client value.
    // This should not affect awaits on existing promises
    // as the promise should already be resolved if the client was defined.
    client = newClient;
    if (client) {
        clientPromise = Promise.resolve(newClient);
    } else {
        clientPromise = new Promise<LDClient>((resolve) => {
            resolvePromise = resolve;
        });
    }
}

export async function configureLaunchDarkly(user: {
    userPrincipalName: string;
    email: string;
    displayName: string;
    firstName: string;
    lastName: string;
}) {
    const { launchDarklyClientId } = config;

    const context = {
        kind: 'user',
        key: user.userPrincipalName.toLowerCase(),
        email: user.email.toLowerCase(),
        name: user.displayName,
        firstName: user.firstName,
        lastName: user.lastName,
    };

    client = initialize(launchDarklyClientId, context);
    resolvePromise(client);

    return Promise.any([
        client.waitUntilReady(),
        new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
    ]);
}

export function clientSignupEnabledSync(): boolean {
    return getVariation('client-signup-page');
}

export async function clientSignupEnabled(ensureInitialized = false): Promise<boolean> {
    if (ensureInitialized) {
        await clientPromise;
    }

    await client?.waitUntilReady();
    return getVariation('client-signup-page');
}

export function getLDClient(): LDClient | undefined {
    return client;
}

function getVariation(key: string, defaultValue = false): boolean {
    return client?.variation(key, defaultValue) ?? defaultValue;
}

export async function updateWithVariation(
    key: string,
    update: (value: unknown) => void
): Promise<void> {
    const initialValue = getVariation(key);
    update(initialValue);

    const client = await clientPromise;

    return client.waitUntilReady().then(() => {
        const readyValue = getVariation(key);
        if (initialValue !== readyValue) {
            update(getVariation(key));
        }
    });
}
