import Graphic from '@arcgis/core/Graphic';

import { SceneLayerSource } from 'components/HighlighSets/buildingSelectionStyleHelpers';
import { isDevPipelineSceneLayer } from 'helpers/devPipelineBuildingLayerHelper';
import { isBuildingEditsLayer } from 'helpers/polygonEditorHelper';
import isDefined from 'utils/isDefined';

export interface BuildingGeometryRef<LayerType extends SceneLayerSource = SceneLayerSource> {
    layerType: LayerType;
    id: number;
}

export function buildingGeometryRefEqual(
    a: BuildingGeometryRef | undefined,
    b: BuildingGeometryRef | undefined
): boolean {
    return a === b || (!!a && !!b && a.layerType === b.layerType && a.id === b.id);
}

export function buildingGeometryRefIdOfType<
    T1 extends SceneLayerSource,
    T2 extends SceneLayerSource
>(
    a: BuildingGeometryRef<T1> | undefined,
    layerType: T2
): T1 extends T2 ? (T2 extends T1 ? number : undefined) : undefined;
export function buildingGeometryRefIdOfType(
    a: BuildingGeometryRef | undefined,
    layerType: string
): number | undefined;
export function buildingGeometryRefIdOfType(
    a: BuildingGeometryRef | undefined,
    layerType: string
): number | undefined {
    return a?.layerType === layerType ? a.id : undefined;
}

export function buildingGeometryRefFromGraphic(
    graphic: Graphic | undefined
): BuildingGeometryRef | undefined {
    if (graphic) {
        if (isDevPipelineSceneLayer(graphic.layer.id)) {
            return { layerType: 'dev-pipeline', id: graphic.attributes['BlackbirdId'] };
        } else if (isBuildingEditsLayer(graphic.layer.id)) {
            return { layerType: 'building-edits-layer', id: graphic.attributes['BlackbirdId'] };
        } else if (graphic.attributes.OSMID) {
            return { layerType: 'osm', id: graphic.attributes.OSMID };
        }
    }

    return undefined;
}

export class BuildingGeometryRefSet {
    private table: Map<number, Array<SceneLayerSource>> = new Map();

    constructor(items?: Iterable<BuildingGeometryRef>) {
        if (!isDefined(items)) {
            return;
        }

        for (const item of items) {
            this.add(item);
        }
    }

    add(ref: BuildingGeometryRef): this {
        let tableEntry = this.table.get(ref.id);
        if (!isDefined(tableEntry)) {
            this.table.set(ref.id, (tableEntry = []));
        }

        if (!tableEntry.includes(ref.layerType)) {
            tableEntry.push(ref.layerType);
        }

        return this;
    }

    delete(ref: BuildingGeometryRef): this {
        const tableEntry = this.table.get(ref.id);
        if (!isDefined(tableEntry)) {
            return this;
        }

        for (let i = 0; i < tableEntry.length; i++) {
            if (tableEntry[i] === ref.layerType) {
                // The rest of this object should enforce that there is only one entry.
                // So, it should this should be fine only removing the first match found.
                tableEntry.splice(i, 1);
                break;
            }
        }

        if (tableEntry?.length === 0) {
            this.table.delete(ref.id);
        }

        return this;
    }

    has(ref: BuildingGeometryRef | undefined): boolean {
        return isDefined(ref) && !!this.table.get(ref.id)?.includes(ref.layerType);
    }

    toArray(): BuildingGeometryRef[] {
        const result: BuildingGeometryRef[] = [];

        for (const [id, entry] of this.table) {
            for (const layerType of entry) {
                result.push({ layerType: layerType, id });
            }
        }

        return result;
    }
}

export function uniqueBuildingGeometryRefs(
    refs: Iterable<BuildingGeometryRef>
): BuildingGeometryRef[] {
    return new BuildingGeometryRefSet(refs).toArray();
}
