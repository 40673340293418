import moment from 'moment';
import { z } from 'zod';

const parseDate = (val: string) => {
    if (!val) return null;

    const date = moment.utc(val);
    if (date.isValid()) {
        return date.toISOString();
    }

    throw new Error('Invalid date format');
};

export const DateRangeInputSchema = z.object({
    from: z.string().transform(parseDate).optional().nullable(),
    to: z.string().transform(parseDate).optional().nullable(),
});

export type DateRangeInput = z.infer<typeof DateRangeInputSchema>;
