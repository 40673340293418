import Camera from '@arcgis/core/Camera';
import * as reactiveUtils from '@arcgis/core/core/reactiveUtils';

import { CameraSettings } from 'helpers/cameraHelper';

const defaultCameraSettings: CameraSettings = {
    tilt: 0,
    heading: 0,
    position: {
        latitude: -100,
        longitude: 30,
        z: 0,
    },
};

let cameraSettings: CameraSettings;

export function getCameraSettings(): CameraSettings {
    return cameraSettings ?? defaultCameraSettings;
}

export function setCameraSettings(camera: Camera) {
    const { tilt, heading, position } = camera;
    const { latitude, longitude, z } = position;
    cameraSettings = {
        position: { latitude, longitude, z },
        heading: heading,
        tilt: tilt,
    } as CameraSettings;
}

export function registerCameraSettingsHandler(view: __esri.SceneView) {
    if (!view) {
        return;
    }
    reactiveUtils.when(
        () => view.stationary === true,
        () => {
            setCameraSettings(view.camera);
        }
    );
}
