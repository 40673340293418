interface NumberFormatOptions extends Intl.NumberFormatOptions {
    trailingZeroDisplay: 'auto' | 'stripIfInteger';
}

const DOLLAR_FORMATTER = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    trailingZeroDisplay: 'stripIfInteger',
} as NumberFormatOptions);

export const pluralize = function (count: number, text: string, includeCount: boolean) {
    includeCount = typeof includeCount === 'undefined' ? false : includeCount;
    let output = `${includeCount ? count.toLocaleString() : ''} `;

    if (count == 1) {
        if (text.endsWith('ies')) {
            text = text.slice(0, -3) + 'y';
        } else if (text.endsWith('s') && text.length > 1 && text[text.length - 2] !== 's') {
            text = text.slice(0, -1);
        }
        output += text;
    } else {
        if (text.endsWith('y')) {
            text = text.slice(0, text.length - 1);
            output += text + 'ies';
        } else if (!text.endsWith('s')) {
            output += text + 's';
        } else {
            output += text;
        }
    }
    return output.trim();
};

export const ordinalize = (value: number) => {
    let ord = 'th';
    if (value % 10 == 1 && value % 100 != 11) {
        ord = 'st';
    } else if (value % 10 == 2 && value % 100 != 12) {
        ord = 'nd';
    } else if (value % 10 == 3 && value % 100 != 13) {
        ord = 'rd';
    }
    return ord;
};

export const isStringValidPath = (path: string) => {
    // Regular expression to match common file path patterns
    const pathRegex = /(?:\/|[A-Za-z]:\\|\\\\|\.\/|\.\.\/)/;

    return pathRegex.test(path);
};

export const toDollarString = (value: number) => {
    return DOLLAR_FORMATTER.format(value);
};
