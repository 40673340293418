import iconUrl from 'icons/custom/pin.svg?url';
import pica from 'pica';

import { isStringValidPath } from 'utils/stringUtils';
import IconImage from './IconImage';

const _cache = new Map();
const resizer = pica();
// Stroke size in percent.
const _strokeSize = 0.08;

const _iconBackgroundPath = 'src/res/img/icons/pinnedProperties/background.svg';
const defaultIconSize = 40;
const defaultIconColor = '#40798D';
const defaultIconName = 'location_on';

interface BuildIconOptions {
    /** An array of images to draw in order. */
    images: Parameters<typeof IconImage>[0][];
    size?: {
        x: number;
        y: number;
    };
    /** A stroke color to apply to the background. */
    stroke?: string;
    shadow?: boolean;
}

interface BuildIconWithIdAsPromiseResult {
    id: string;
    image: () => Promise<HTMLCanvasElement>;
}
export function buildIconWithIdAsPromise(
    options: BuildIconOptions
): BuildIconWithIdAsPromiseResult {
    const key = JSON.stringify(options);

    return {
        id: key,
        image: function () {
            return Promise.resolve(buildIcon(options));
        },
    };
}

interface BuildIconWithIdFunctionResult {
    id: string;
    image: HTMLCanvasElement;
}
export function buildIconWithIdFunction(
    options: BuildIconOptions
): Promise<BuildIconWithIdFunctionResult> {
    const key = JSON.stringify(options);

    return Promise.resolve(buildIcon(options)).then(function (image) {
        return {
            id: key,
            image: image,
        };
    });
}

export function buildIcon(options: BuildIconOptions): Promise<HTMLCanvasElement> {
    const key = JSON.stringify(options);

    if (_cache.has(key)) {
        return _cache.get(key);
    }

    const promise = new Promise<HTMLCanvasElement>(function (resolve) {
        const images = options.images.map(function (item) {
            return new IconImage(item);
        });

        const size = options.size || { x: 128, y: 128 };

        const canvas = document.createElement('canvas');
        canvas.width = size.x;
        canvas.height = size.y;

        loadImages(images).then(async () => {
            for (const image of images) {
                await createImageRenderer(image, size, canvas);
            }
            const image = options.shadow ? drawShadow(canvas) : canvas;
            resolve(image);
        });
    });
    _cache.set(key, promise);
    return promise;
}

/**
 * Used to render an image.
 * @param {jll.util.iconBuilder.IconImage} image - The image to render onto the canvas.
 * @param {Object} size - The size of the canvas to draw.
 * @param {HTMLCanvasElement} canvas - The final canvas we will render into.
 */
async function createImageRenderer(
    image: IconImage,
    size: { x: number; y: number },
    canvas: HTMLCanvasElement
) {
    /**
     * If there is a stroke, draw it before the image
     */
    if (image.stroke) {
        await createCanvas(image, size).then(function (image: IconImage) {
            drawImage(image.image!, image.stroke, canvas);
            image.scale = (image.scale ? image.scale : 1) * (1 - _strokeSize);
        });
    }
    /**
     * Draw the image.
     */
    await createCanvas(image, size).then(function (image: IconImage) {
        drawImage(image.image!, image.color, canvas);
    });
}

function createCanvas(iconImage: IconImage, size: { x: number; y: number }): Promise<IconImage> {
    return new Promise(function (resolve, reject) {
        if (typeof iconImage !== 'undefined' && typeof iconImage.data !== 'undefined') {
            resizeImage(iconImage.data, size, iconImage.scale).then(function (resizedCanvas) {
                iconImage.image = resizedCanvas;
                resolve(iconImage);
            });
        } else reject(iconImage);
    });
}

/**
 * Load the collection of images.
 * @param {jll.util.iconBuilder.IconImage[]} images
 * @returns {Promise}
 */
function loadImages(images: IconImage[]) {
    return Promise.all(
        images.map(function (image) {
            return image.load();
        })
    );
}

function drawImage(
    source: HTMLImageElement | ImageBitmap | HTMLCanvasElement,
    color: string,
    canvas: HTMLCanvasElement
) {
    const ctx = canvas.getContext('2d');

    const x = canvas.width * 0.5 - Math.round(source.width * 0.5);
    const y = canvas.height * 0.5 - Math.round(source.height * 0.5);

    if (ctx) {
        ctx.drawImage(
            color == null ? source : tintImage(source, color),
            x,
            y,
            source.width,
            source.height
        );
    }
}

function resizeImage(
    image: HTMLImageElement,
    size: { x: number; y: number },
    scale: number
): Promise<HTMLCanvasElement> {
    const canvas = document.createElement('canvas');
    scale = typeof scale === 'undefined' ? 1 : scale;
    canvas.width = size.x * scale;
    canvas.height = size.y * scale;

    const img = document.createElement('canvas');

    img.width = image.width;
    img.height = image.height;
    const ctx = img.getContext('2d');
    if (ctx) ctx.drawImage(image, 0, 0);
    // Resize from Canvas/Image to another Canvas
    return resizer.resize(img, canvas, {
        quality: 1,
    });
}

/**
 * Tint the image to the specified color.
 */
function tintImage(
    image: HTMLImageElement | ImageBitmap | HTMLCanvasElement,
    color: string
): HTMLCanvasElement {
    // Create a temporary tint canvas
    const tintCanvas = document.createElement('canvas');
    tintCanvas.width = image.width;
    tintCanvas.height = image.height;
    const ctx = tintCanvas.getContext('2d');

    if (ctx) {
        // fill offscreen tint Canvas with the tint color
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, tintCanvas.width, tintCanvas.height);

        // destination atop makes a result with an alpha channel identical to fgimage but with all pixels retaining their original color
        ctx.globalCompositeOperation = 'destination-atop';
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0);
    }
    return tintCanvas;
}

function drawShadow(canvas: HTMLCanvasElement): HTMLCanvasElement {
    const shadowCanvas = document.createElement('canvas');
    shadowCanvas.width = canvas.width + 16;
    shadowCanvas.height = canvas.height + 16;
    const shadow2D = shadowCanvas.getContext('2d');
    if (shadow2D) {
        shadow2D.shadowBlur = 12;
        shadow2D.shadowOffsetY = 8;
        shadow2D.shadowOffsetX = 0;
        shadow2D.shadowColor = 'rgba(0, 0, 0, 0.4)';
        shadow2D.drawImage(canvas, 8, 8);
    }
    return shadowCanvas;
}

export const getPinSizeForDisplay = (iconType: string, iconSize: number) => {
    return (iconType === 'numbered' ? 8 : 0) + iconSize;
};

const createNumberedIconOptions = (
    icon: string,
    backgroundColor: string,
    foregroundColor: string,
    size: number
) => {
    return {
        images: [
            {
                url: `data:image/svg+xml;base64,${btoa(
                    `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle stroke="${foregroundColor}" fill="${backgroundColor}" cx="8" cy="8" r="7"></circle></svg>`
                )}`,
            },
            {
                url: icon,
                color: foregroundColor,
                scale: 1,
            },
        ],
        size: {
            x: size || 40,
            y: size || 40,
        },
        shadow: true,
    };
};

/**
 * Build an icon and return a
 * @param {string} [icon='generic'] - The icon path used for the foreground image.
 * @param {Cesium.Color} [backgroundColor='#777777']
 * @returns {Promise}
 */
export const buildAmenityLikeIcon = (
    icon: string,
    backgroundColor: string,
    foregroundColor: string,
    size: number
) => {
    return buildIconWithIdAsPromise(
        createNumberedIconOptions(icon, backgroundColor, foregroundColor, size)
    );
};

export const createPinIcon = (iconPath = 'generic', iconColor = '#0000FF', size = 40) => {
    return buildIconWithIdAsPromise({
        images: [
            {
                url: _iconBackgroundPath,
                color: '#FFFFFF',
            },
            {
                url: iconPath,
                scale: 0.75,
                color: iconColor,
            },
        ],
        size: {
            x: size,
            y: size,
        },
        shadow: true,
    });
};

export const createPinIconNoBackground = (iconPath: string, size: number) => {
    return buildIconWithIdAsPromise({
        images: [
            {
                url: _iconBackgroundPath,
                color: '#FFFFFF',
            },
            {
                url: iconPath,
            },
        ],
        size: {
            x: size || 40,
            y: size || 40,
        },
        shadow: true,
    });
};

interface NumberedPinIcon {
    type: 'numbered';
    font: string;
    numberingType: 'arabic';
    name: string;
    /** Data uri for the preview icon */
    url: string;
}

export function renderNumberedIcon(
    pinIcon: Omit<NumberedPinIcon, 'url'>,
    i: number,
    fontSize: number
): string {
    return `data:image/svg+xml;base64,${btoa(
        `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="${fontSize}px" font-family="${pinIcon.font}" font-weight="bold">${i}</text></svg>`
    )}`;
}

/**
 * Build an icon and return a
 * @param {string} [icon='generic'] - The icon path used for the foreground image.
 * @param {Cesium.Color} [backgroundColor='#777777']
 * @returns {Promise}
 */
export const buildNumberedIcon = (
    icon: string,
    backgroundColor: string,
    foregroundColor: string,
    size: number
) => {
    const options = {
        images: [
            {
                url: `data:image/svg+xml;base64,${btoa(
                    `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle stroke="${foregroundColor}" fill="${backgroundColor}" cx="8" cy="8" r="7"></circle></svg>`
                )}`,
            },
            {
                url: icon,
                scale: 1,
                color: foregroundColor,
            },
        ],
        size: {
            x: size,
            y: size,
        },
        shadow: false,
    };
    return buildIcon(options);
};

export const fetchMaterialSymbolSvg = async (iconName: string, iconSize = 24) => {
    const maxPinSize = 48;
    const url = `https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsoutlined/${iconName}/wght200/${Math.min(
        iconSize,
        maxPinSize
    )}px.svg`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('SVG not found');
        }

        let svgData = await response.text();

        if (iconSize > maxPinSize) {
            svgData = svgData.replace(/width="(\d+)px"/, `width="${iconSize}px"`);
            svgData = svgData.replace(/height="(\d+)px"/, `height="${iconSize}px"`);
        }

        return `data:image/svg+xml;base64,${btoa(svgData)}`;
    } catch (error) {
        console.error('Error fetching SVG:', error);
    }
};

export const isCloudinaryImageUrl = (url: string) => {
    return url.includes('res.cloudinary.com');
};

// Google Material symbols - Function to create the icon URL with appropriate size and color
export const createIconForMaterialSymbols = async (
    iconName = defaultIconName,
    iconColor = '#FFF',
    iconSize = defaultIconSize,
    backgroundColor = defaultIconColor
) => {
    if (isCloudinaryImageUrl(iconName)) {
        return iconName;
    }
    const isCustomIconUrl = isStringValidPath(iconName);
    const materialSymbol = isCustomIconUrl
        ? iconName
        : await fetchMaterialSymbolSvg(iconName, iconSize);
    if (!materialSymbol) return;

    const foregroundSvg = materialSymbol ?? iconUrl;
    const scale = isCustomIconUrl ? 1 : 0.75;

    const options = {
        images: [
            {
                url: `data:image/svg+xml;base64,${btoa(
                    `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><circle stroke="${iconColor}" fill="${backgroundColor}" cx="8" cy="8" r="7"></circle></svg>`
                )}`,
            },
            {
                url: foregroundSvg,
                color: iconColor,
                scale,
            },
        ],
        size: {
            x: iconSize,
            y: iconSize,
        },
        shadow: false,
    };
    return buildIcon(options);
};
