import { startCase } from 'lodash';

import { stringToColor } from './colorUtils';

export function buildUserInitialsFromDisplayName(
    displayName: string,
    splitOn: string | RegExp = /\s+/
): string[] {
    return displayName
        .trim()
        .split(splitOn)
        .map((item) => item[0]?.toUpperCase())
        .filter((item) => item);
}

export function buildUserInitialsFromEmail(email: string) {
    return email
        .replace(/@.*/gim, '')
        .split('.')
        .map(function (item) {
            return item[0].toUpperCase();
        })
        .join('');
}

export function getDisplayNameFromUpn(upn: string) {
    return startCase(
        upn.replace(/@.*/gim, '').split('.').join(' ').replace(/[0-9]/g, '').toLowerCase()
    );
}

export function avatarColor(email: string) {
    return stringToColor(email);
}

export function permissionTypeName(accessType: number) {
    switch (accessType) {
        case 1:
            return 'View';
        case 2:
            return 'Edit';
        case 3:
            return 'Owner';
        default:
            return '';
    }
}
