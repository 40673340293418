export const simpleDownload = async (url: string, filename: string) => {
    try {
        const response = await fetch(url);
        const element = document.createElement('a');
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        element.setAttribute('href', objectUrl);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        URL.revokeObjectURL(objectUrl);
    } catch {
        throw new Error('Failed to download file');
    }
};

export const downloadBlob = (blob: Blob, filename: string) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
};

export const dataUrlToBlob = (dataUrl: string, mimeType = 'image/webp') => {
    // Split the Data URL into two parts: the header and the base64-encoded data
    const parts = dataUrl.split(',');

    // Decode the base64-encoded data
    const binary = atob(parts[1]);

    // Create an empty array to store the character codes
    const array: number[] = [];

    // Iterate through each character in the binary string
    for (let i = 0; i < binary.length; i++) {
        // Get the character code for the current character and append it to the array
        array.push(binary.charCodeAt(i));
    }

    // Create a Blob from the Uint8Array containing the character codes
    return new Blob([new Uint8Array(array)], { type: mimeType });
};
