export const emailToDisplayName = (email: string): string => {
    const splits = email.split('@')[0]?.split('.');
    if (splits.length < 2) return splits[0];

    return splits
        .map((item) => {
            return item.charAt(0).toUpperCase() + item.slice(1);
        })
        .join(' ');
};
