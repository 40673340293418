import endpoints from 'utils/apiClient/endpoints';

const activityLogApi = endpoints.activityLogs;

const COMMON_FETCH_OPTIONS: RequestInit = { keepalive: true };

export async function logCreatePresentation() {
    try {
        await activityLogApi.presentations.post({
            fetchOptions: COMMON_FETCH_OPTIONS,
        });
    } catch (e) {
        console.error('Error in insertCreatePresentationLog: ' + e);
    }
}

export async function logOpenPresentation(presentationId: number, marketId: number) {
    try {
        await endpoints.activityLogs.openPresentation.post({
            templateValues: { presentationId },
            queryParameters: { marketId: marketId },
            fetchOptions: COMMON_FETCH_OPTIONS,
        });
    } catch (e) {
        console.error('Error in logOpenPresentation: ' + e);
    }
}

export async function logLogin() {
    try {
        await activityLogApi.login.post({ fetchOptions: COMMON_FETCH_OPTIONS });
    } catch (e) {
        console.error('Error in insertLoginLog: ' + e);
    }
}

export async function logLogout() {
    try {
        await activityLogApi.logout.post({ fetchOptions: COMMON_FETCH_OPTIONS });
    } catch (e) {
        console.error('Error in insertLogoutLog: ' + e);
    }
}

export async function logCreateSurvey(marketId: number, pursuitId: number) {
    try {
        await activityLogApi.surveys.post({
            queryParameters: { marketId: marketId, pursuitId: pursuitId },
            fetchOptions: COMMON_FETCH_OPTIONS,
        });
    } catch (e) {
        console.error('Error in insertCreateSurveyLog: ' + e);
    }
}
