export const PROPERTY_CLIENT = 'propertyServiceEndpoint';
export const AVAILABILITY_CLIENT = 'availabilityServiceEndpoint';
export const LEASE_CLIENT = 'leaseServiceEndpoint';
export const INQUIRY_CLIENT = 'inquiryServiceEndpoint';
export const MARKETSPHERE_CLIENT = 'marketsphereServiceEndpoint';
export const SALES_CLIENT = 'salesServiceEndpoint';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const buildInput = (model: any): string => {
    const output = [];
    for (const key in model) {
        const value = model[key];
        output.push(inputFromType(key, value));
    }

    return `${output.join(',')}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const inputFromType = (name: any, value: any): string | null => {
    let output = null;
    const valueType = typeof value;

    switch (valueType) {
        case 'object':
            output =
                value == null || value == 'undefined'
                    ? null
                    : Array.isArray(value)
                    ? `[${value.map((item) => inputFromType(null, item)).join(',')}]`
                    : `{${buildInput(value)}}`;
            break;
        case 'function':
            return inputFromType(name, value.call(this, name));
        case 'string':
            output = `"${value}"`;
            break;
        case 'symbol':
            output = `${value.description}`;
            break;
        default:
            output = `${value}`;
    }

    return name ? `${name}:${output}` : output;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const buildQuery = (model: any) => {
    let output = '';

    if (model.parameters) {
        const parameters = [];

        for (const key in model.parameters) {
            parameters.push(inputFromType(key, model.parameters[key]));
        }

        output += `(${parameters.join(',')})`;
    }

    const queries = [];
    for (const key in model) {
        if (key === 'parameters') continue;

        const value = model[key];
        if (!value) queries.push(key);
        else queries.push(`${key}${buildQuery(model[key])}`);
    }

    if (queries.length > 0) output += `{${queries.join(' ')}}`;

    return output;
};

const clauseEnum = Object.freeze({
    OR: Symbol('OR'),
    AND: Symbol('AND'),
});

const sourceSystemEnum = Object.freeze({
    MARKET_SPHERE: Symbol('MARKET_SPHERE'),
});

const gqlUtils = {
    buildInput,
    buildQuery,
    clauseEnum,
    sourceSystemEnum,
};
export default gqlUtils;
