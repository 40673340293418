import { ApiEndpointError } from './ApiEndpoint';

function wait(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export interface RetryOnErrorOptions {
    retryLimit?: number;
    retryTimer?: number;
    expBackoff?: boolean;
    expBackoffFactor?: number;
    maxTimeout?: number;
    shouldRetry?: (e: unknown) => unknown;
    maxConcurrent?: { count: number; id: number | string | symbol };
    maxRandomOffset?: number;
    _suppressRetryWarnings?: boolean;
}

export function retryOnError<This, P extends unknown[], R>(
    thunk: (this: This, ...args: P) => Promise<R>,
    options: RetryOnErrorOptions = {}
): (this: This, ...args: P) => Promise<R> {
    const {
        retryLimit = 5,
        retryTimer = 500,
        expBackoff = true,
        expBackoffFactor = 2,
        maxTimeout = 5_000,
        maxRandomOffset = 100,
        shouldRetry = () => true,
        _suppressRetryWarnings = false,
    } = options;

    return async function (this: This, ...args: P): Promise<R> {
        let timeout = retryTimer;
        let retries = retryLimit;
        /* eslint-disable-next-line no-constant-condition
           --------
           We exit this loop by either returning the return value of thunk or
           throwing an error after enough retries, so this is alright.
        */
        while (true) {
            try {
                return await thunk.apply(this, args);
            } catch (err) {
                if (!shouldRetry(err)) {
                    throw err;
                }
            }

            if (retries-- <= 0) {
                throw new Error('Too many requests');
            }
            // Random offset to prevent all the retries from happening at the same time
            const randomOffset = Math.random() * maxRandomOffset;
            await wait(Math.min(timeout, maxTimeout) + randomOffset);
            if (expBackoff) {
                timeout = Math.min(timeout * expBackoffFactor, maxTimeout);
            }
            if (!_suppressRetryWarnings) {
                console.warn('Retrying query. Remaining attempts: %i', retries + 1);
            }
        }
    };
}

export function retryOn429Response<This, P extends unknown[], R>(
    thunk: (this: This, ...args: P) => Promise<R>,
    options: Omit<RetryOnErrorOptions, 'shouldRetry'> = {}
): (this: This, ...args: P) => Promise<R> {
    return retryOnError(thunk, {
        ...options,
        shouldRetry: (err) => err && err instanceof ApiEndpointError && err.statusCode === 429,
    });
}
