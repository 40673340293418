import { Cloudinary, CloudinaryImage } from '@cloudinary/url-gen';

import config from 'utils/config';
export const cloudinary = new Cloudinary({ cloud: { cloudName: config.cloudinaryName } });
export const basePath = config.cloudinaryFolder;

interface ImageProps {
    folder: string;
    id: string | number;
    version?: string | number;
    format?: 'jpg' | 'png' | 'webp' | 'jpeg' | 'gif' | 'svg' | 'auto';
}

const setVersion = (image: CloudinaryImage, version?: string | number) => {
    if (version) {
        image.setVersion(version);
    }
};

const setImageFormat = (image: CloudinaryImage, format?: string) => {
    if (format) {
        image.format(format);
    }
};

export const getImage = ({ folder, id, version, format = 'webp' }: ImageProps) => {
    const image = cloudinary.image(`${basePath}/${folder}/${id}`);

    setVersion(image, version);
    setImageFormat(image, format ?? 'webp');

    return image;
};

export const getImageUrl = (props: ImageProps) => {
    const image = getImage(props);

    return image.toURL();
};

export const extractDetailsFromUrl = (url: string) => {
    const urlParts = url
        .replace(/(.*)res.cloudinary.com\//i, '') // Remove cloudinary origin
        .replace(/\/(w|h|c|ar|q|f|e|a|fl|b|l|t|bo|r|u|dpr|g|vs|du|so)_([^/]*)\//i, '/') // Remove the section for existing transformation if it exists
        .replace(/\/v[0-9]+\//i, '/') // Remove the version if it exists
        .split('/');

    const cloudName = urlParts[0];
    const resourceType = urlParts[1];
    const deliveryType = urlParts[2];
    const publicId = urlParts.slice(3).join('/').split('.')[0];
    return { cloudName, resourceType, deliveryType, publicId };
};

const scratchCloudinary = new Cloudinary();
export const getCloudinaryImagesFromFullUrl = (mediaAsset: string) => {
    if (!mediaAsset.includes('res.cloudinary.com')) return null;

    const { cloudName, publicId } = extractDetailsFromUrl(mediaAsset);

    scratchCloudinary.setConfig({
        cloud: {
            cloudName,
        },
    });

    return scratchCloudinary.image(publicId);
};

export const resizeCompanyImage = (image?: string) => {
    if (!image) {
        return undefined;
    }
    const baseUrl = 'https://res.cloudinary.com/marketsphere/image/upload/';
    const transformations = 'c_pad,w_32,h_32/';
    const publicId = image.split('/').pop();

    return `${baseUrl}${transformations}${publicId}`;
};
