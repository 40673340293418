import { CSSProperties } from 'react';
import { MenuProps } from '@jll/react-ui-components';

export type MenuItem = Required<MenuProps>['items'][number];

export interface ContextMenuItemProps {
    label?: React.ReactNode;
    key?: React.Key | null;
    icon?: React.ReactNode;
    children?: MenuItem[];
    onClick?: () => void;
    type?: string | 'group';
    style?: CSSProperties;
}

export const ContextMenuItem = ({
    label,
    key,
    icon,
    children,
    onClick,
    type,
    style,
}: ContextMenuItemProps): MenuItem => {
    return {
        label,
        key,
        icon,
        children,
        onClick,
        type,
        style,
    } as MenuItem;
};
