let screenshotHandler: (
    options?: __esri.SceneViewTakeScreenshotOptions
) => Promise<__esri.SceneViewScreenshot>;

export async function takeScreenshot(
    options?: __esri.SceneViewTakeScreenshotOptions
): Promise<__esri.SceneViewScreenshot> {
    return screenshotHandler(options);
}

export function registerScreenshotHandler(
    handler: (
        options?: __esri.SceneViewTakeScreenshotOptions
    ) => Promise<__esri.SceneViewScreenshot>
) {
    screenshotHandler = handler;
}
