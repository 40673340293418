import { z } from 'zod';

import { CspPropertyInputSchema } from './CspPropertyInputSchema';

export const CspSubPropertyInputSchema = z.object({
    values: z.array(CspPropertyInputSchema),
});

const CspSubPropertyInputSchemaWithOptionalPropertyStatus = CspSubPropertyInputSchema.extend({
    propertyStatus: CspPropertyInputSchema.shape.propertyStatus.optional(),
});

export type CspSubPropertyInput = z.infer<
    typeof CspSubPropertyInputSchemaWithOptionalPropertyStatus
>;
