export default class DefaultMap<K, V> extends Map<K, V> {
    constructor(
        private createDefault: (key: K) => V,
        iterable?: Iterable<readonly [K, V]> | null | undefined
    ) {
        super(iterable);
    }

    get(key: K): V {
        if (this.has(key)) {
            // We know that it is V since it `has` the key
            return super.get(key) as V;
        }
        const value = this.createDefault(key);
        this.set(key, value);
        return value;
    }
}
