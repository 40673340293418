/**
 * The image objects used for building icons.
 *
 * @constructor
 *
 * @param {Object} options
 * @param {string} options.url
 * @param {HTMLCanvasElement|ImageBitmap} [options.image]
 * @param {number} [options.scale]
 * @param {string} [options.color]
 * @param {string} [options.stroke]
 * @class
 */
export default function IconImage(options) {
    /**
     * Instead of a url, you can pass in an HTML Canvas or Image object.
     * @member {HTMLCanvasElement|ImageBitmap}
     */
    this.image = options.image;

    /**
     * A relative or absolute url to load the image by.
     * @type {string}
     */
    this.url = options.url;

    /**
     * The scale used when sizing the image.
     * @type {number}
     */
    this.scale = options.scale;

    /**
     * A hex value color.
     * @type {string}
     */
    this.color = validateHexString(options.color);

    /**
     * A hex color for the stroke.
     * @type {string}
     */
    this.stroke = options.stroke;

    /** @type {HTMLImageElement | undefined} */
    this.data = undefined;
}

IconImage.prototype = {
    load: function () {
        return new Promise(
            function (resolve, reject) {
                if (!this.url && this.image) {
                    resolve(this);
                } else if (!this.url) {
                    reject('IconImage Must have either a url or image');
                }

                loadImageAsDataUri(this.url).then((dataUri) => {
                    var htmlImage = new Image();
                    htmlImage.onload = function () {
                        this.data = htmlImage;
                        resolve(this);
                    }.bind(this);

                    htmlImage.onerror = function (e) {
                        reject(e);
                    };

                    htmlImage.src = dataUri;
                });
            }.bind(this)
        );
    },
};

function validateHexString(text) {
    if (!text) return;

    if (text.indexOf('#') === -1) return '#' + text;
    return text;
}

/**
 * Loads an image from a url into a data url format.
 *
 * If the image is already a data url, it resolves to the value passed in.
 *
 * @param {string} imageUrl The url from which to fetch the image
 * @param {AbortSignal} [signal] An abort controller for aborting the fetch
 * @returns {Promise<string>} A data url representation of the image
 */
function loadImageAsDataUri(imageUrl, signal) {
    if (imageUrl.startsWith('data:')) {
        return Promise.resolve(imageUrl);
    }

    let headers;

    if (imageUrl.startsWith(jll.STATIC_CONFIG.blackbirdApiUrl)) {
        headers = {
            Authorization: `Bearer ${jll.store.get('user/accessToken').token}`,
            'Subscription-Key': jll.STATIC_CONFIG.blackbirdApiSubscriptionKey,
        };
    }

    return fetch(imageUrl, { signal: signal, headers })
        .then((response) => response.blob())
        .then(
            (imageBlob) =>
                new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = () => {
                        reject(reader.error);
                    };
                    reader.readAsDataURL(imageBlob);
                })
        );
}
