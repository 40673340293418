import { z } from 'zod';

import {
    PropertyStatusEnum,
    PropertyStatusValues,
    PropertyTypeEnum,
} from 'types/searchSchemas/SearchFieldSchemas';
import { NumericRangeInputSchema } from '../searchSchemas/NumericRangeInputSchema';
import { CspBuildingClassEnum } from './CspFieldsSchemas';
import { CspMarketsHierarchyInputSchema } from './CspMarketsHierarchySchema';

export const CspPropertyInputSchema = z.object({
    id: z.array(z.string()).optional(),
    propertyType: z.array(z.string()).optional(),
    propertyTypeSourceValue: z.array(PropertyTypeEnum).optional(),
    marketsHierarchy: CspMarketsHierarchyInputSchema.optional().nullable(),
    totalArea: NumericRangeInputSchema.optional(),
    totalFloorCount: NumericRangeInputSchema.optional(),
    totalMarketLevel2: z.array(z.string()).optional(),
    propertyStatus: z.array(PropertyStatusEnum).default(PropertyStatusValues),
    buildingStatus: z.array(PropertyStatusEnum).optional(),
    construction: z
        .object({
            values: z.object({
                buildingClass: z.array(CspBuildingClassEnum).optional(),
            }),
        })
        .optional(),
    buildingClass: z.array(CspBuildingClassEnum).optional(),
    yearBuilt: NumericRangeInputSchema.optional(),
    indicator: z
        .object({
            values: z.array(
                z.object({
                    inStatistics: z.boolean().optional(),
                })
            ),
        })
        .optional(),
    usage: z
        .object({
            values: z.array(
                z.object({
                    subType: z.array(z.string()).optional(),
                })
            ),
        })
        .optional(),
    companies: z
        .object({
            values: z.array(
                z.object({
                    companyRole: z.string(),
                    companyName: z.array(z.string()),
                })
            ),
        })
        .optional(),
});

const CspPropertyInputSchemaWithOptionalPropertyStatus = CspPropertyInputSchema.extend({
    propertyStatus: CspPropertyInputSchema.shape.propertyStatus.optional(),
});

export type CspPropertyInput = z.infer<typeof CspPropertyInputSchemaWithOptionalPropertyStatus>;
