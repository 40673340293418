import { MenuItemType } from '@jll/react-ui-components';

export function filteredMenuItems(
    items: Array<MenuItemType & { visible?: boolean }>
): MenuItemType[] {
    const result = [];

    for (const item of items) {
        if (!('visible' in item)) {
            result.push(item);
        } else if (item.visible) {
            const { visible: _visible, ...rest } = item;
            result.push(rest);
        }
    }

    return result;
}
