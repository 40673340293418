import { CustomUserClaim } from '@okta/okta-auth-js';

import {
    clearOneTimeTokenAccessToken,
    getClientUserOneTimeToken,
    getClientUserToken,
} from '../apiClient/shareLinkSessionStorage';

const checkOneTimeToken = (expireTime: number, showRenewAccessMessage: () => Promise<unknown>) => {
    setTimeout(async () => {
        if (await showRenewAccessMessage()) {
            window.location.href = `${
                window.location.origin
            }/clientsharelink/${getClientUserOneTimeToken()}`;
        }
    }, expireTime * 1000);
};
interface LoadClientSessionResult {
    accessToken: string;
    signOut: () => void;
}
export function loadClientSession(
    showRenewAccessMessage: () => Promise<unknown>
): LoadClientSessionResult | undefined {
    const clientToken = getClientUserToken();
    if (!clientToken) {
        return;
    }

    try {
        checkOneTimeToken(clientToken.expires_in as number, showRenewAccessMessage);
    } catch (e) {
        clearOneTimeTokenAccessToken();
        return;
    }

    if (new Date(clientToken.expiresAt as string) < new Date()) {
        clearOneTimeTokenAccessToken();
        return;
    }

    return {
        accessToken: clientToken.access_token as string,
        signOut() {
            clearOneTimeTokenAccessToken();
        },
    };
}

export function convertBase64AdGuid(
    adGuidBase64: CustomUserClaim | CustomUserClaim[]
): string | undefined {
    if (!adGuidBase64) return;

    const byteArray = Uint8Array.from(atob(adGuidBase64 as string), (c) => c.charCodeAt(0));
    function byteToHex(byte: number) {
        return ('0' + byte.toString(16)).slice(-2);
    }
    const guidArray = Array.from(byteArray);
    let guid = '';
    guid +=
        byteToHex(guidArray[3]) +
        byteToHex(guidArray[2]) +
        byteToHex(guidArray[1]) +
        byteToHex(guidArray[0]) +
        '-';
    guid += byteToHex(guidArray[5]) + byteToHex(guidArray[4]) + '-';
    guid += byteToHex(guidArray[7]) + byteToHex(guidArray[6]) + '-';
    guid += byteToHex(guidArray[8]) + byteToHex(guidArray[9]) + '-';
    for (let i = 10; i < 16; i++) {
        guid += byteToHex(guidArray[i]);
    }
    return guid;
}
