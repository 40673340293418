import { z } from 'zod';

export const PropertyTypeEnum = z.enum(['Office', 'Flex R&D', 'Lab', 'Industrial']);
export type PropertyType = z.infer<typeof PropertyTypeEnum>;
export const PropertyTypeValues = PropertyTypeEnum.options;

export const PropertyStatusEnum = z.enum([
    'Existing',
    'Proposed Short-Term',
    'Proposed Long-Term',
    'Under Construction',
    'Under Renovation',
]);
export type PropertyStatus = z.infer<typeof PropertyStatusEnum>;
export const PropertyStatusValues = PropertyStatusEnum.options;

export const BuildingClassEnum = z.enum(['Class A', 'Class B', 'Class C', 'Trophy']);
export type BuildingClass = z.infer<typeof BuildingClassEnum>;
export const BuildingClassValues = BuildingClassEnum.options;

export const LeaseTypeEnum = z.enum(['Direct', 'Sublet', 'Sublease']);
export type LeaseType = z.infer<typeof LeaseTypeEnum>;
export const LeaseTypeValues = LeaseTypeEnum.options;
