import { RuleObject } from '@jll/react-ui-components';

export const VALIDATE_MESSAGES = {
    required: '${label} is required',
    whitespace: '${label} cannot be empty',
    types: {
        email: '${label} is not a valid email address',
    },
    string: {
        min: '${label} must be at least ${min} characters',
    },
};

function characterSetRule(name: string, regex: RegExp) {
    return {
        validator: function (rule: RuleObject, value: string): Promise<void> {
            if (regex.test(value)) {
                return Promise.resolve();
            }
            const article = /[aeiou]/i.test(name[0]) ? 'an' : 'a';
            return Promise.reject(`\${label} requires ${article} ${name}`);
        },
    };
}

/** Rule for ensuring form item has at least one lowercase character (a-z) */
export const LOWERCASE_CHARSET_RULE = characterSetRule('lowercase character (a-z)', /[a-z]/);
/** Rule for ensuring form item has at least one uppercase character (A-Z) */
export const UPPERCASE_CHARSET_RULE = characterSetRule('uppercase character (A-Z)', /[A-Z]/);
/** Rule for ensuring form item has at least one numeral character (0-9) */
export const NUMERIC_CHARSET_RULE = characterSetRule('numeral (0-9)', /[0-9]/);
/** Rule for ensuring form item has at least one special character (i.e. !, $, #, %, etc.) */
export const SPECIAL_CHARSET_RULE = characterSetRule(
    'special character (i.e. !, $, #, %, etc.)',
    /[^a-zA-Z0-9]/
);
/** Rule for ensuring form item meets JLL's password character set rules */
export const PASSWORD_CHARSET_RULES = [
    LOWERCASE_CHARSET_RULE,
    UPPERCASE_CHARSET_RULE,
    NUMERIC_CHARSET_RULE,
    SPECIAL_CHARSET_RULE,
];
