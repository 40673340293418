import { DeepPartial } from '@reduxjs/toolkit';

import { OneTimeTokenLoginResult, TokenResponse } from 'api/clientShareApi';

/**
 * Constant that defines the use limit for client shared presentations.
 */
export const CLIENT_PRESENTATION_USE_LIMIT = 7;

export function storeOneTimeTokenInfo(oneTimeToken: string, result: OneTimeTokenLoginResult) {
    setClientUserToken(result.token);
    setClientUserOneTimeToken(oneTimeToken);
    setShareTokenUseCount(result.useCount);
    setShareTokenLoginData(result);
}

export function clearOneTimeTokenAccessToken() {
    clearClientUserToken();
}

/**
 * Session storage key for storing and retrieving the client user access token.
 */
const CLIENT_USER_TOKEN_KEY = 'client-user-access-token';

export function getClientUserToken(): Partial<TokenResponse> | null {
    const value = jsonParseFromStorage(sessionStorage, CLIENT_USER_TOKEN_KEY);
    if (value != null && typeof value === 'object') {
        return value;
    }

    return null;
}

function setClientUserToken(value: TokenResponse | null) {
    sessionStorage.setItem(CLIENT_USER_TOKEN_KEY, JSON.stringify(value));
}

function clearClientUserToken() {
    sessionStorage.removeItem(CLIENT_USER_TOKEN_KEY);
}

/**
 * Session storage key for storing and retrieving the client user one-time use token.
 */
const CLIENT_USER_ONE_TIME_KEY = 'client-user-one-time-token';

export function getClientUserOneTimeToken(): string | null {
    return sessionStorage.getItem(CLIENT_USER_ONE_TIME_KEY);
}

function setClientUserOneTimeToken(oneTimeToken: string) {
    sessionStorage.setItem(CLIENT_USER_ONE_TIME_KEY, oneTimeToken);
}

function _clearClientUserOneTimeToken() {
    sessionStorage.removeItem(CLIENT_USER_ONE_TIME_KEY);
}

/**
 * Session storage key for storing and retrieving the use count for a specific shared presentation.
 */
const CLIENT_PRESENTATION_USE_COUNT = 'client-presentation-use-count';

export function getShareTokenUseCount(): number | null {
    const value = Number(sessionStorage.getItem(CLIENT_PRESENTATION_USE_COUNT));

    return isNaN(value) ? 0 : value;
}

function setShareTokenUseCount(useCount: number) {
    sessionStorage.setItem(CLIENT_PRESENTATION_USE_COUNT, useCount.toString());
}

function _clearShareTokenUseCount() {
    sessionStorage.removeItem(CLIENT_PRESENTATION_USE_COUNT);
}

/**
 * Session storage key that stores the information regarding the share link including:
 * - The use count
 * - The associated presentation id
 */
const CLIENT_USER_SHARE_INFO = 'client-user-share-info';

export function getShareTokenLoginData(): DeepPartial<OneTimeTokenLoginResult> | null {
    const value = jsonParseFromStorage(sessionStorage, CLIENT_USER_SHARE_INFO);
    if (value != null && typeof value === 'object') {
        return value;
    }

    return null;
}

function setShareTokenLoginData(value: OneTimeTokenLoginResult) {
    sessionStorage.setItem(CLIENT_USER_SHARE_INFO, JSON.stringify(value));
}

function _clearShareTokenLoginData() {
    sessionStorage.removeItem(CLIENT_PRESENTATION_USE_COUNT);
}

/**
 * Localstorage key used for doing cookie-like tracking of if a user has created a JLL User.
 * The value should be of the form:
 * { "signedUp": true, "userName": "username", "signupTime": "iso formatted date"}
 */
const EXTERNAL_USER_SIGNUP_STATUS = 'external-user-signup-status';

export interface SignupStatus {
    signedUp: boolean;
    userName?: string;
    signupTime?: Date;
}

interface SignupStatusStorage {
    signedUp?: unknown;
    userName?: unknown;
    signupTime?: unknown;
}

/**
 * Max time that the signup should "emulate" being signed in in milliseconds: 1hr
 * 1hr =  (60 min / 1hr) * (60 s / 1 min) * (1000 ms / 1 s)
 */
const MAX_TIME_TO_SKIP_SIGNUP_MS = 60 * 60 * 1_000;

export function shouldSkipSignupPage(value?: SignupStatus): boolean {
    if (!value) {
        value = getUserSignupStatus();
    }

    if (!value.signedUp || value.signupTime === undefined) {
        return false;
    }
    const timeSinceSignup = Number(new Date()) - Number(value.signupTime);
    return value.signedUp && timeSinceSignup < MAX_TIME_TO_SKIP_SIGNUP_MS;
}

export function isSignedUpExternalUser(value?: SignupStatus): boolean {
    if (!value) {
        value = getUserSignupStatus();
    }

    return value.signedUp;
}

function parseSignupStatusStorage(value: unknown): SignupStatusStorage | null {
    if (value == null) {
        return null;
    }

    return value;
}

export function getUserSignupStatus(): SignupStatus {
    const value = parseSignupStatusStorage(
        jsonParseFromStorage(localStorage, EXTERNAL_USER_SIGNUP_STATUS)
    );

    if (value === null) {
        return { signedUp: false };
    }

    return {
        signedUp: !!value.signedUp,
        userName: typeof value.userName === 'string' ? value.userName : undefined,
        signupTime: typeof value.signupTime === 'string' ? new Date(value.signupTime) : undefined,
    };
}

export function setUserSignupStatus(value: SignupStatus) {
    localStorage.setItem(
        EXTERNAL_USER_SIGNUP_STATUS,
        JSON.stringify({
            signedUp: value.signedUp,
            userName: value.userName,
            signupTime: value.signupTime?.toISOString(),
        })
    );
}

function jsonParseFromStorage(storage: Storage, key: string): unknown {
    const storageValue = storage.getItem(key);

    if (storageValue == null) {
        return undefined;
    }

    try {
        return JSON.parse(storageValue);
    } catch (e) {
        console.error(`Error parsing key='${key}' from storage: ${e}`);
        return undefined;
    }
}

/**
 * Session storage key to check if shared link is magic link or not.
 */
const CLIENT_USER_IS_MAGIC_LINK = 'client-user-is-magic-link';

export function getClientUserMagicLinkState(): boolean {
    const isMagicLink = sessionStorage.getItem(CLIENT_USER_IS_MAGIC_LINK);
    return isMagicLink === 'true' ? true : false;
}

export function setClientUserMagicLinkState(value: boolean) {
    sessionStorage.setItem(CLIENT_USER_IS_MAGIC_LINK, value.toString());
}

export function clearClientUserMagicLinkState() {
    sessionStorage.removeItem(CLIENT_USER_IS_MAGIC_LINK);
}
