import { z } from 'zod';

import { CspSubPropertyInputSchema } from 'types/cspInputSchemas/CspSubPropertyInputSchema';
import { PropertyStatusEnum } from './SearchFieldSchemas';

export const ExtendedCspSubPropertyInputSchema = CspSubPropertyInputSchema.extend({
    values: z.array(
        CspSubPropertyInputSchema.shape.values.element.extend({
            propertyStatus: z.array(PropertyStatusEnum).optional(),
        })
    ),
});
