import { Cloudinary } from '@cloudinary/url-gen';
import { Resize } from '@cloudinary/url-gen/actions';

import config from './config';

export const getCloudinaryUrl = (
    url: string,
    layerId: string | number,
    isAdmin?: boolean,
    width?: number,
    height?: number
) => {
    if (url.indexOf('res.cloudinary.com') > -1) {
        const uri = new URL(url);
        const name = uri.pathname.split('/')[1];
        const cloudinaryProvider = new Cloudinary({ cloud: { cloudName: name } });
        const cloudinaryImage = cloudinaryProvider.image(url.split('image/upload/', 2).pop());
        cloudinaryImage.resize(
            Resize.limitFit()
                .width(width || 1080)
                .height(height || 1080)
        );
        cloudinaryImage.quality('auto');
        cloudinaryImage.format('webp');
        return cloudinaryImage.toURL();
    }

    if (!isAbsoluteUrl(url) && layerId) {
        return `${window.STATIC_CONFIG.blackbirdApiUrl}${encodeURIComponent(
            url
        )}&layerId=${layerId}&isAdmin=${isAdmin}`;
    } else {
        return window.STATIC_CONFIG.blackbirdApiUrl + encodeURIComponent(url);
    }
};

const isAbsoluteUrl = (url: string) => {
    return url.indexOf('http://') === 0 || url.indexOf('https://') === 0 || url.indexOf('//') === 0;
};

export const loadImageAsDataUri = (imageUrl: string, signal?: AbortSignal) => {
    if (imageUrl.startsWith('data:')) {
        return Promise.resolve(imageUrl);
    }

    let headers;
    const accessToken = config.accessToken;

    if (imageUrl.startsWith(config.blackbirdApiUrl)) {
        headers = {
            Authorization: `Bearer ${accessToken}`,
            'Subscription-Key': config.blackbirdApiSubscriptionKey,
        };
    }

    return fetch(imageUrl, { signal: signal, headers })
        .then((response) => response.blob())
        .then(
            (imageBlob) =>
                new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = () => {
                        reject(reader.error);
                    };
                    reader.readAsDataURL(imageBlob);
                })
        );
};
