let getLayerViewHandler: (layer: __esri.Layer) => Promise<__esri.LayerView>;

export function getLayerView(layer: __esri.Layer): Promise<__esri.LayerView> {
    return getLayerViewHandler(layer);
}

export function registerGetLayerViewHandler(
    handler: (layer: __esri.Layer) => Promise<__esri.LayerView>
) {
    getLayerViewHandler = handler;
}
