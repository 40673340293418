import { escapeRegExp } from 'lodash';

export function getUrlPattern(url: string): RegExp {
    let inVariable = false;
    const patternParts = ['^'];
    let startIndex = 0;
    let i;

    if (url.endsWith('/')) {
        url = url.slice(0, url.length - 1);
    }

    for (i = 0; i < url.length; i++) {
        const char = url[i];
        if (!inVariable) {
            if (char === '{') {
                patternParts.push(escapeRegExp(url.slice(startIndex, i)));
                startIndex = i;
                inVariable = true;
            }
        } else {
            if (char === '}') {
                patternParts.push('[^\\/]*');
                startIndex = i + 1;
                inVariable = false;
            }
        }
    }
    if (startIndex < i) {
        patternParts.push(url.slice(startIndex, i));
    }
    patternParts.push('/?$');
    return new RegExp(patternParts.join(''), 'i');
}

export class EndpointAllowlistEntry {
    pathPattern: RegExp;
    methods: string[];
    constructor({ path, methods }: { path: string; methods: string[] }) {
        this.pathPattern = getUrlPattern(path);
        this.methods = methods;
    }

    test(urlPath: string, method: string): boolean {
        return this.methods.indexOf(method) > -1 && this.pathPattern.test(urlPath);
    }
}

export const getPresentationUrl = (presentationId: number, clientVersion = 0) => {
    return clientVersion === 1
        ? `/presentation/${presentationId}`
        : `/index.html?P=${presentationId}`;
};
