import type { Color } from 'cesium';

export function stringToColor(text: string): string {
    let num = 0;
    for (let i = 0; i < text.length; i++) {
        num += text.charCodeAt(i);
    }
    return selectColor(num);
}

function selectColor(num: number): string {
    const hue = num * 137.508; // use golden angle approximation
    return `hsl(${hue}, 50%, 50%)`;
}

const colorCodes = {
    white: '#ffffff',
    black: '#202020',
};

export function getOppositeShade(color: string | null | undefined, threshold?: number) {
    threshold = typeof threshold === 'undefined' ? 215 : threshold;
    if (!color) return colorCodes.white;
    const luminance = getLuminance(color);
    if (luminance && luminance > threshold) return colorCodes.black;
    else return colorCodes.white;
}

export function getLuminance(rgb: string | null | undefined) {
    if (!rgb) return null;
    const rgbArray = rgb
        .replace(/[^\d,]/g, '')
        .split(',')
        .map(Number);
    return 0.2126 * rgbArray[0] + 0.7152 * rgbArray[1] + 0.0722 * rgbArray[2];
}

export function colorToHex(color: Color) {
    return color
        .toBytes()
        .slice(0, 3)
        .map(function (channel) {
            return channel.toString(16).padStart(2, '0');
        })
        .join('');
}

export function hexToRgb(color: string) {
    if (!color) return null;
    let r = 1,
        g = 1,
        b = 1,
        _a = 1;
    color = color.indexOf('#') >= 0 ? color.replace('#', '') : color;
    if (typeof color === 'string') {
        const hexArray = color.match(/.{2}/g);
        if (hexArray && hexArray.length == 4) {
            // Kml Color
            r = parseInt(hexArray[3], 16);
            g = parseInt(hexArray[2], 16);
            b = parseInt(hexArray[1], 16);
            _a = parseInt(hexArray[0], 16);
        } else if (hexArray && hexArray.length == 3) {
            // Rgb color
            r = parseInt(hexArray[0], 16);
            g = parseInt(hexArray[1], 16);
            b = parseInt(hexArray[2], 16);
            _a = 1;
        }
    }
    return 'rgb(' + r + ',' + g + ',' + b + ')';
}

/**
 * Convert a hex color to a Cesium.Color
 * @param {string} hex
 * @returns {Cesium.Color}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const hexToColor = (hex: any, typeofColor: typeof Color) => {
    if (typeof hex !== 'string') return hex;

    hex = hex.replace(/#/gim, '');
    let r = 0,
        g = 0,
        b = 0,
        a = 255;
    const hexArray = hex.match(/.{2}/g);
    if (hexArray.length === 4) {
        // Kml Color
        r = parseInt(hexArray[3], 16);
        g = parseInt(hexArray[2], 16);
        b = parseInt(hexArray[1], 16);
        a = parseInt(hexArray[0], 16);
    } else if (hexArray.length === 3) {
        // Rgb color
        r = parseInt(hexArray[0], 16);
        g = parseInt(hexArray[1], 16);
        b = parseInt(hexArray[2], 16);
    }
    return typeofColor.fromBytes(r, g, b, a);
};

/**
 * Get a slightly darker color for a polygon outline, or, a slightly lighter one for dark polygons.
 * @param {Cesium.Color} color - The color to change.
 * @returns {Cesium.Color} - A slightly darker or brighter color.
 */
export const outlineColorForPolygonColor = (
    color: Cesium.Color,
    typeofColor: typeof Cesium.Color,
    amount = 0.2
): Cesium.Color => {
    const brightness = (color.red + color.green + color.blue) / 3;
    if (brightness > 0.1) {
        return color.darken(amount, new typeofColor());
    } else {
        return color.brighten(amount, new typeofColor());
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
const hex = (x: any) => {
    return ('0' + parseInt(x).toString(16)).slice(-2);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- Legacy use of any
export const rgb2hex = (rgb: any) => {
    if (rgb.search('rgb') == -1) {
        return rgb;
    } else {
        rgb = rgb.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+))?\)$/);
        return '#' + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
    }
};
