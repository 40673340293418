import { CSSProperties } from 'react';

import { HEADER_HEIGHT, RIGHT_SIDEBAR_WIDTH } from '../constants/layer.constants';
import { ImageModelTypes } from '../helpers/imageLayerHelper';
import {
    AbsoluteOverlay,
    CanvasSize,
    GroundOverlay,
    ImageOverlayMetadata,
    KMLScreenOverlay,
    ScreenOverlay,
} from '../types/Layers/ImageLayerMetadata';

export const createOverlayFromMetadata = (
    metadata: ImageOverlayMetadata
): ScreenOverlay | AbsoluteOverlay | GroundOverlay | undefined => {
    const base = {
        href: metadata.image?.href,
        key: metadata.key,
    };
    switch (metadata.clampingType) {
        case ImageModelTypes.SCREEN:
            return {
                ...metadata.screenOverlayEdit,
                ...base,
            } as ScreenOverlay;
        case ImageModelTypes.ABSOLUTE:
            return {
                ...metadata.absoluteOverlayEdit,
                ...base,
            } as AbsoluteOverlay;
        case ImageModelTypes.CLAMPED_TO_GROUND:
            return {
                ...metadata.groundOverlayEdit,
                ...base,
            } as GroundOverlay;
    }
};

export const mediaUrlAdapter = (url: string) => {
    const isYoutubeLink = url.match(
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)(?:\S+)?$/
    );
    if (isYoutubeLink) return `https://www.youtube.com/embed/${isYoutubeLink[1]}`;
    return url;
};

export const getImageOverlaySize = (
    href: string
): Promise<{ resizedWidth: number; resizedHeight: number }> => {
    return new Promise((resolve, reject) => {
        fetch(href)
            .then((response) => response.blob())
            .then((blob) => {
                const img = new Image();
                img.src = URL.createObjectURL(blob);
                img.onload = (_e) => {
                    const aspectRatio = img.naturalWidth / img.naturalHeight;
                    const maxImageWidth = window.innerWidth * 0.4 - RIGHT_SIDEBAR_WIDTH;
                    const maxImageHeight = window.innerHeight - HEADER_HEIGHT;

                    let resizedWidth = Math.min(maxImageWidth, img.naturalWidth);
                    let resizedHeight = resizedWidth / aspectRatio;

                    if (resizedHeight > maxImageHeight) {
                        resizedHeight = maxImageHeight;
                        resizedWidth = resizedHeight * aspectRatio;
                    }
                    resolve({
                        resizedWidth: Math.round(resizedWidth),
                        resizedHeight: Math.round(resizedHeight),
                    });
                    URL.revokeObjectURL(img.src);
                };
            })
            .catch((error) => reject(error));
    });
};

export const transformKMLScreenOverlay = (
    kmlScreenOverlay: KMLScreenOverlay,
    screenOverlayKey: string,
    canvasSize: CanvasSize
): Promise<ScreenOverlay> => {
    return new Promise((resolve, _reject) => {
        const cssProperties: CSSProperties = { position: 'absolute' };
        const img = new Image();
        img.src = kmlScreenOverlay.href;
        img.onload = (_e) => {
            const _offsetWidth = img.offsetWidth ?? 0;
            const _offsetHeight = img.offsetHeight ?? 0;
            // Extract size information
            const sizeXY = kmlScreenOverlay.sizeXY;
            if (sizeXY) {
                const width = sizeXY.x;
                const height = sizeXY.y;
                const widthUnits = sizeXY.xUnits;
                const heightUnits = sizeXY.yUnits;
                let widthCssValue, heightCssValue;
                let aspectRatio = false;
                if (widthUnits === 'fraction') {
                    widthCssValue = width * 100 + '%';
                    if (width == 0) aspectRatio = true;
                } else if (widthUnits === 'pixels') {
                    widthCssValue = width + 'px';
                } else if (widthUnits === 'insetPixels') {
                    widthCssValue = `${width}px`;
                }

                if (heightUnits === 'fraction') {
                    heightCssValue = height * 100 + '%';
                    if (height == 0) aspectRatio = true;
                } else if (heightUnits === 'pixels') {
                    heightCssValue = height + 'px';
                } else if (heightUnits === 'insetPixels') {
                    heightCssValue = `${height}px`;
                }

                if (aspectRatio) {
                    if (widthCssValue !== '0%') {
                        widthCssValue = `${img.naturalWidth * width}px`;
                        img.style.width = widthCssValue;
                        img.style.height = 'auto';
                    } else if (heightCssValue !== '0%') {
                        heightCssValue = `${img.naturalHeight * height}px`;
                        img.style.height = heightCssValue;
                        img.style.width = 'auto';
                    }
                } else {
                    // set CSS properties
                    img.style.width = widthCssValue ?? `${img.naturalWidth}px`;
                    cssProperties.width = widthCssValue ?? `${img.naturalWidth}px`;
                    img.style.height = heightCssValue ?? `${img.naturalHeight}px`;
                    cssProperties.height = heightCssValue ?? `${img.naturalHeight}px`;
                }
            }

            // Extract position information
            const screenXY = kmlScreenOverlay.screenXY;
            if (screenXY) {
                const xPos = screenXY.x;
                const yPos = screenXY.y;
                const xPosUnits = screenXY.xUnits;
                const yPosUnits = screenXY.yUnits;
                // Calculate position in CSS units
                let xPosCssValue, yPosCssValue;
                if (xPosUnits === 'fraction') {
                    xPosCssValue = xPos * 100 + '%';
                } else if (xPosUnits === 'pixels') {
                    xPosCssValue = xPos + 'px';
                } else if (xPosUnits === 'insetPixels') {
                    xPosCssValue = `${xPos}px`;
                }

                if (yPosUnits === 'fraction') {
                    yPosCssValue = yPos * 100 + '%';
                } else if (yPosUnits === 'pixels') {
                    yPosCssValue = yPos + 'px';
                } else if (yPosUnits === 'insetPixels') {
                    yPosCssValue = `${yPos}px`;
                }

                // set css properties
                img.style.left = xPosCssValue ?? '0px';
                cssProperties.left = xPosCssValue ?? '0px';
                img.style.top = yPosCssValue ?? '0px';
                cssProperties.top = yPosCssValue ?? '0px';
            }

            // Extract rotation information
            const rotationXY = kmlScreenOverlay.rotationXY;
            if (rotationXY) {
                const xRot = rotationXY.x;
                const yRot = rotationXY.y;
                const xRotUnits = rotationXY.xUnits;
                const yRotUnits = rotationXY.yUnits;

                if (xRotUnits === 'fraction' && yRotUnits === 'fraction') {
                    const _degreesX = xRot * 360;
                    const _degreesY = yRot * 360;
                    // cssProperties.transform = `rotateX(${degreesX}deg) rotateY(${degreesY}deg)`;
                }
            }
            resolve({
                screenX: parseFloat(img.style.left),
                screenY: parseFloat(img.style.top),
                width: parseFloat(img.style.width),
                height: parseFloat(img.style.height),
                opacity: 1,
                rotation: 0,
                key: screenOverlayKey,
                href: kmlScreenOverlay.href,
                relativeScreenX: parseFloat(img.style.left) / canvasSize.width,
                relativeScreenY: parseFloat(img.style.top) / canvasSize.height,
                relativeWidth: parseFloat(img.style.width) / canvasSize.width,
                relativeHeight: parseFloat(img.style.height) / canvasSize.height,
            });
        };
    });
};

export const getIframeSize = (iframeString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(iframeString, 'text/html');
    const iframe = doc.querySelector('iframe');
    const width = iframe?.getAttribute('width');
    const height = iframe?.getAttribute('height');

    if (iframe && width && height) {
        const widthValue = parseFloat(width);
        const heightValue = parseFloat(height);
        if (!isNaN(widthValue) && !isNaN(heightValue)) {
            return {
                width: parseFloat(width),
                height: parseFloat(height),
            };
        }
    }
};
